<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import RevenueAnalytics from "@/views/pages/orders/revenueProfit.vue";
import ChartOrders from "@/views/pages/orders/chartSuccessAndFillOrders.vue";
import {GetDataMethods, notificationMethods} from "@/state/helpers";

/**
 * Orders Component
 */
export default {
  components: {
    RevenueAnalytics,
    Layout,
    ChartOrders,
    PageHeader
  },
  data() {
    return {
      title: "Orders",
      type: "orders",
      type_sort: "weekly",
      labelsIds: [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "9",
      ],
      labelsSuccessFill: [65, 65, 65, 45, 56, 80, 50, 20, 0, 0, 50],
      dataOrdersSuccessAndFill: {
        success: [544, 555, 222, 88, 77, 500, 400],
        fill: [44, 77, 99, 66, 33, 44, 77, 11, 22]
      },

      dataIds: [65, 65, 65, 45, 56, 80, 50, 20, 0, 0, 50],
      items: [
        {
          text: "Ecommerce"
        },
        {
          text: "Orders",
          active: true
        }
      ],
    };
  },
  methods: {
    ...GetDataMethods,
    ...notificationMethods,
    getDataOrdersUnits() {
      this.getData("get_count_orders_pricing").then((res) => {
        this.labelsIds = res.data.data.count_orders.labels
        this.dataIds = res.data.data.count_orders.data

      })
    },
    getDataOrdersSuccessAndFill(t, t_sort) {
      this.getData("get_count_orders_success_and_fill/" + t + "/" + t_sort).then((res) => {
        this.labelsSuccessFill = res.data.data.count_orders.orders_paid.labels
        this.dataOrdersSuccessAndFill = {
          success: res.data.data.count_orders.orders_paid.data,
          fill: res.data.data.count_orders.orders_unpaid.data
        }
      })
    },
    changeType(t) {
      this.type = t
      this.getDataOrdersSuccessAndFill(this.type, this.type_sort)

    },
    changeTypeSort(t_sort) {
      this.type_sort = t_sort
      this.getDataOrdersSuccessAndFill(this.type, this.type_sort)

    }
  },
  mounted() {
    this.getDataOrdersUnits()
    this.getDataOrdersSuccessAndFill(this.type, this.type_sort)
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>

    <div class="row">
      <div class="col-xl-12">
        <RevenueAnalytics class="h-auto"/>
      </div>

    </div>





    <div class="row">
      <div class="col-lg-12">

        <div class="card">
          <div class="card-body">

            <div class="float-right d-none d-md-inline-block">
              <div class="btn-group mb-2">
                <button @click="changeType('orders')" type="button" class="btn btn-sm btn-light">Units</button>
                <button @click="changeType('bills')" type="button" class="btn btn-sm btn-light ">Providers</button>
                <button @click="changeType('banks')" type="button" class="btn btn-sm btn-light">Banks</button>
                <button @click="changeType('money')" type="button" class="btn btn-sm btn-light">Money</button>
              </div>
            </div>
            <h4 class="card-title mb-4">success and fill orders</h4>

            <!-- Bar Chart -->
            <ChartOrders title="Count Order" :labels="labelsSuccessFill" :data="dataOrdersSuccessAndFill"/>
          </div>
        </div>
      </div>

    </div>

  </Layout>
</template>