<script>
import ChartistProfitCustomer from "@/components/ChartistProfite.vue";


/**
 * Revenue Analytics component
 */
export default  {
  components: {ChartistProfitCustomer},
  methods: {
    change_header_data(header){
      this.totalProfit = header.totalProfit
      this.countAll = header.countAll
      this.countAllVereified = header.countAllVereified
    },
    change(){

      this.labels = ["from method"]
    },
    changeTypeDate(t) {
        this.typeDate = t
    },
    changeType(t) {
        this.type = t
    }
  },
  mounted() {
    this.change_header_data({countOrders:7,countCustomers:7,totalProfit:7})
  },
  data() {
    return {
      countAll : 5,
      countAllVereified : 7,
      totalProfit : 1,
      typeDate: "monthly",
      type: "orders",
      data_countries: false,
      customerDateAsDaly: false,
      customerDateAsWeekly: false,
      customerDateAsMonthly: true,

      series: [
        {
          name: "2020",
          type: "column",
          data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16]
        },
        {
          name: "2019",
          type: "line",
          data: [23, 32, 27, 38, 27, 32, 27, 38, 22, 31, 21, 16]
        }
      ],

      chartOptions: {
        chart: {
          height: 280,
          type: "line",
          toolbar: {
            show: false
          }
        },
        stroke: {
          width: [0, 3],
          curve: "smooth"
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "20%"
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        colors: ["#5664d2", "#1cbb8c"],
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec"
        ]
      }
    };
  },


};
</script>

<template>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="float-right d-none d-md-inline-block">
            <div class="btn-group mb-2">
              <button @click="changeTypeDate('daly')" type="button" class="btn btn-sm btn-light">daily</button>
              <button @click="changeTypeDate('weekly')" type="button" class="btn btn-sm btn-light ">Weekly</button>
              <button @click="changeTypeDate('monthly')" type="button" class="btn btn-sm btn-light">Monthly</button>
            </div>
          </div>
          <h4 class="card-title mb-4">Profits</h4>
          <div class="float-right d-none d-md-block">
            <div class="btn-group mb-2">
              <button @click="changeType('orders')" type="button" class="btn btn-sm btn-light">Orders</button>
              <button @click="changeType('orders')" type="button" class="btn btn-sm btn-light ">Money</button>
              <button @click="changeType('banks')" type="button" class="btn btn-sm btn-light">Bills</button>
              <button @click="changeType('bills')" type="button" class="btn btn-sm btn-light">Providers</button>
            </div>
          </div>
          <div class="row text-center">
            <div class="col-sm-4">
              <h5 class="mb-0">{{ countAll }}</h5>
              <p class="text-muted">Count All</p>
            </div>

            <div class="col-sm-4">
              <h5 class="mb-0">{{ countAllVereified }}</h5>
              <p class="text-muted">Count All Verified</p>
            </div>

            <div class="col-sm-4">
              <h5 class="mb-0">{{ totalProfit }}</h5>
              <p class="text-muted">Total Profit</p>
            </div>
          </div>
          <!-- Line Chart -->
          <ChartistProfitCustomer :type="type" :typeDate="typeDate" @change_header_data="change_header_data" :height="300"  />
        </div>
      </div>
    </div>
  </div>

</template>