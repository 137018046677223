<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,
  props:{
    labels : {
      default: [

      ]
    },
    data : {
      default : {success : [],fill:[]}
    },
    title:{
      default : "Count Customer"
    }
  },
  watch:{

    labels: function () { // watch it
      this.render()
    },
    data: function () { // watch it
      this.render()
    },
  },
  methods:{
    render(){
      this.renderChart(
          {
            labels: this.labels,
            datasets: [
              {
                barPercentage: 0.4,
                label: this.title,
                borderColor: "rgba(187,28,28,0.8)",
                borderWidth: 1,
                hoverBackgroundColor: "rgba(28, 187, 140, 0.9)",
                hoverBorderColor: "rgba(28, 187, 140, 0.9)",
                data: this.data.fill
              },
              {
                barPercentage: 0.4,
                label: this.title,
                backgroundColor: "rgba(28,187,140,0.31)",
                borderColor: "rgba(28, 187, 140, 0.8)",
                borderWidth: 1,
                hoverBackgroundColor: "rgba(28, 187, 140, 0.9)",
                hoverBorderColor: "rgba(28, 187, 140, 0.9)",
                data: this.data.success
              },
            ]
          },
          {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              xAxes: [
                {
                  gridLines: {
                    color: "rgba(166, 176, 207, 0.1)"
                  }
                }
              ],
              yAxes: [
                {
                  gridLines: {
                    color: "rgba(166, 176, 207, 0.1)"
                  }
                }
              ]
            }
          }
      );
    }
  },
  mounted() {
    this.render()
  }
};
</script>